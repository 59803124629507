import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { errorRoute } from './layouts/error/error.route';
import { DEBUG_INFO_ENABLED } from './app.constants';
import { Authority } from './config/authority.constants';
import { UserRouteAccessService } from './core/auth/user-route-access-service';
import { UserRouteRoleAccessService } from './core/auth/user-route-role-access-service';
import { CaseRedirectorComponent } from './shared/redirector/case-redirector.component';
import { PasswordResetFinishComponent } from './account/password-reset/finish/password-reset-finish.component';
import { PasswordResetInitComponent } from './account/password-reset/init/password-reset-init.component';
import { ActivateComponent } from './account/activate/activate.component';
import { LoginModalComponent } from './shared/login/login.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { MainComponent } from './layouts/main/main.component';

const LAYOUT_ROUTES = [...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: MainComponent,
          children: [
            {
              path: '',
              pathMatch: 'full',
              redirectTo: 'home'
            },
            {
              path: 'home',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./home/home.module').then(m => m.CupouiHomeModule) }]
            },
            {
              path: 'account',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) }]
            },
            {
              path: 'cases',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService, UserRouteRoleAccessService],
              children: [{ path: '', loadChildren: () => import('./cases/cases.module').then(m => m.CupouiCasesModule) }]
            },
            {
              path: 'insights',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService, UserRouteRoleAccessService],
              children: [{ path: '', loadChildren: () => import('./insights/insights.module').then(m => m.CupouiInsightsModule) }]
            },
            {
              path: 'case',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService, UserRouteRoleAccessService],
              children: [{ path: ':id', loadChildren: () => import('./case/case.module').then(m => m.CupouiCaseModule) }]
            },
            {
              path: 'products-contracts',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [
                {
                  path: '',
                  loadChildren: () => import('./products-contracts/products-contracts.module').then(m => m.CupouiProductsContractsModule),
                  canActivate: [UserRouteRoleAccessService]
                }
              ]
            },
            {
              path: 'major-incidents',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [
                {
                  path: '',
                  loadChildren: () => import('./major-incidents/major-incidents.module').then(m => m.CupouiMajorIncidentsModule)
                }
              ]
            },
            {
              path: 'economy',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [
                {
                  path: '',
                  loadChildren: () => import('./economy/economy.module').then(m => m.CupouiEconomyModule)
                }
              ]
            },
            {
              path: 'settings',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./settings/settings.module').then(m => m.CupouiSettingsModule) }]
            },
            {
              path: 'helpAndContact',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./contact/contact.module').then(m => m.CupouiContactModule) }]
            },
            {
              path: 'help',
              data: {
                authorities: [Authority.USER, Authority.ADMIN]
              },
              canActivate: [UserRouteAccessService],
              children: [{ path: '', loadChildren: () => import('./help/help.module').then(m => m.CupouiHelpModule) }]
            }
          ]
        },

        {
          path: 'auth',
          component: AuthComponent,
          children: [
            {
              path: 'sign-in',
              component: LoginModalComponent
            },
            {
              path: 'activate',
              component: ActivateComponent
            },
            {
              path: 'reset/request',
              component: PasswordResetInitComponent
            },
            {
              path: 'reset/finish',
              component: PasswordResetFinishComponent
            }
          ]
        },
        // Route for specifying case-basic route as fx. /SECC-000-000-020
        {
          path: ':id',
          component: CaseRedirectorComponent
        },
        // Fallback when no prior routes is matched
        { path: '**', redirectTo: '/auth/sign-in', pathMatch: 'full' },

        ...LAYOUT_ROUTES
      ],
      { enableTracing: DEBUG_INFO_ENABLED }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
